<template>
  <div>
    <grid
      ref="grid"
      route_grid_path="grid"
      route_name="user_exceptions_grid"
      order_column="created_at"
      direction="desc"
      :service="$exceptionService"
      :hide_actions="true"
      showTotalText="Eventos "
      :fetchOnMounted="false"
    >
      <template v-slot:gridThead>
        <vs-th width="20%" sort-key="created_at">Data/Hora</vs-th>
        <vs-th >ID</vs-th>
        <vs-th >{{ $t('description') }}</vs-th>
        <vs-th v-if="hasJustification(data)" sort-key="data.justification">{{ $t('data_viewer.keys.justification') }}</vs-th>
        <vs-th sort-key="type">{{ $t('tipo') }}</vs-th>
        <vs-th sort-key="supervisor_name" v-if="hasSupervisor(data)">{{ $t('supervisor') }}</vs-th>
        <vs-th>{{ $t('Actions') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :key="data[i]" v-for="(tr, i) in props.data">
          <vs-td>
            {{ formattedDate(_.get(tr, 'created_at', null)) }}
          </vs-td>
          <vs-td>
            {{ tr.id }}
          </vs-td>
          <vs-td>
            <div class="_event-detail-text overflow-y-scroll text-sm">
              <div v-html-safe="_.get(tr, 'data.text', '<label class=\'text-danger\'>VAZIO</label>')" />
            </div>
          </vs-td>
          <vs-td v-if="hasJustification(props.data)">
            <div class="_event-detail-text overflow-y-scroll break-all">
              {{justification(tr)}}
            </div>
          </vs-td>
          <vs-td>
            {{ tr.type }}
          </vs-td>
          <vs-td v-if="hasSupervisor(props.data)">
            {{ _.get(tr, 'supervisor_name') }}
          </vs-td>
          <vs-td>
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
              @click="openDataViewer(tr)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </grid>

    <vs-popup :title="getDataViewerPopupTitle()" :active.sync="showDataViewer">
      <div class="mb-4 flex flex-col" v-if="currentlyViewingEvent && 'type' in currentlyViewingEvent">
        <div>
          <label class="font-bold">{{ $t('tipo-de-evento') }} </label><span>{{ _.get(currentlyViewingEvent, 'type') }}</span>
        </div>
      </div>
      <data-viewer :data="currentlyViewingData"/>
    </vs-popup>
  </div>
</template>

<script>
import { arraySort, arraySortDate } from '@/util/Util'
import DataViewer from '@/components/DataViewer.vue'
export default {
  components: { DataViewer },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedData() {
      const data = this._.cloneDeep(this.data)
      const key = this._.get(this.sort, 'key', 'created_at')
      const direction = this._.get(this.sort, 'direction', 'desc')
      const directionSign = direction === 'asc' ? 1 : -1

      let sortFunction = arraySort(key, directionSign, true)

      if (key === 'created_at') sortFunction = arraySortDate(key, directionSign)

      data.sort(sortFunction)

      return data
    }
  },

  data: () => ({
    sort: {
      key: 'created_at',
      direction: 'desc'
    },
    currentlyViewingData: {},
    currentlyViewingEvent: {},
    currentlyViewingType: '',
    showDataViewer: false
  }),

  methods: {
    fillCustomFilters(...params) {
      this.$utils.vue.childDo(this, 'grid',
        (grid) => {
          grid.fillCustomFilters(...params)
        }
      )
    },
    fetchGridData(...params) {
      this.$utils.vue.childDo(this, 'grid',
        (grid) => {
          grid.fetchGridData(...params)
        }
      )
    },
    getSeverityClass(severity) {
      switch (severity) {
      case 'L':
        return 'text-success'
      case 'M':
        return 'text-warning'
      case 'H':
        return 'text-danger'
      default: 
        return 'text-primary'
      }
    },
    justification(event) {
      return this._.get(event, 'data.justification', null)
    },
    hasProp(prop) {
      
    },
    hasJustification(events) {
      return false
      // return events.some((event) => {
      //   return 'justification' in event.data
      // })
    },
    hasSupervisor(events) {
      return events.some((event) => {
        return 'supervisor_name' in event
      })
    },
    getDataViewerPopupTitle() {
      const type = this.currentlyViewingType
      const typei18nKey = `client_exceptions.type.${type}`
      return `Evento: ${this.$t(typei18nKey)}`
    },
    formattedDate(dateString) {
      if (dateString !== null) {
        return this.$utils.format.dateWithFormat(dateString, 'DD/MM/YYYY - kk:mm:ss.SSS')
      } else {
        return '!'
      }
    },
    openDataViewer(event) {
      const type = event.type
      const data = event.data
      this.showDataViewer = true
      this.currentlyViewingEvent = event
      this.currentlyViewingData = {
        emitted_at: event.created_at,
        ...data
      }
      this.currentlyViewingType = type
    },
    handleSort(key, direction) {
      if (key !== null && direction !== null) {
        this.sort = {
          key,
          direction
        }
      } else {
        this.sort = {
          key: 'data.emitted_at',
          direction: 'desc'
        }
      }
    },
    grid() {
      return this.$refs['grid']
    }
  }
}
</script>

<style lang="scss" scoped>
._event-detail-text {
  max-height: 4rem;
}
</style>