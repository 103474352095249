var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("grid", {
        ref: "grid",
        attrs: {
          route_grid_path: "grid",
          route_name: "user_exceptions_grid",
          order_column: "created_at",
          direction: "desc",
          service: _vm.$exceptionService,
          hide_actions: true,
          showTotalText: "Eventos ",
          fetchOnMounted: false,
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c(
                  "vs-th",
                  { attrs: { width: "20%", "sort-key": "created_at" } },
                  [_vm._v("Data/Hora")]
                ),
                _c("vs-th", [_vm._v("ID")]),
                _c("vs-th", [_vm._v(_vm._s(_vm.$t("description")))]),
                _vm.hasJustification(_vm.data)
                  ? _c(
                      "vs-th",
                      { attrs: { "sort-key": "data.justification" } },
                      [_vm._v(_vm._s(_vm.$t("data_viewer.keys.justification")))]
                    )
                  : _vm._e(),
                _c("vs-th", { attrs: { "sort-key": "type" } }, [
                  _vm._v(_vm._s(_vm.$t("tipo"))),
                ]),
                _vm.hasSupervisor(_vm.data)
                  ? _c("vs-th", { attrs: { "sort-key": "supervisor_name" } }, [
                      _vm._v(_vm._s(_vm.$t("supervisor"))),
                    ])
                  : _vm._e(),
                _c("vs-th", [_vm._v(_vm._s(_vm.$t("Actions")))]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, i) {
                return _c(
                  "vs-tr",
                  { key: _vm.data[i] },
                  [
                    _c("vs-td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formattedDate(_vm._.get(tr, "created_at", null))
                          ) +
                          " "
                      ),
                    ]),
                    _c("vs-td", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    _c("vs-td", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "_event-detail-text overflow-y-scroll text-sm",
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: _vm._.get(
                                  tr,
                                  "data.text",
                                  "<label class='text-danger'>VAZIO</label>"
                                ),
                                expression:
                                  "_.get(tr, 'data.text', '<label class=\\'text-danger\\'>VAZIO</label>')",
                              },
                            ],
                          }),
                        ]
                      ),
                    ]),
                    _vm.hasJustification(props.data)
                      ? _c("vs-td", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "_event-detail-text overflow-y-scroll break-all",
                            },
                            [_vm._v(" " + _vm._s(_vm.justification(tr)) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("vs-td", [_vm._v(" " + _vm._s(tr.type) + " ")]),
                    _vm.hasSupervisor(props.data)
                      ? _c("vs-td", [
                          _vm._v(
                            " " + _vm._s(_vm._.get(tr, "supervisor_name")) + " "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "vs-td",
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            icon: "EyeIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDataViewer(tr)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.getDataViewerPopupTitle(),
            active: _vm.showDataViewer,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDataViewer = $event
            },
          },
        },
        [
          _vm.currentlyViewingEvent && "type" in _vm.currentlyViewingEvent
            ? _c("div", { staticClass: "mb-4 flex flex-col" }, [
                _c("div", [
                  _c("label", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("tipo-de-evento")) + " "),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._.get(_vm.currentlyViewingEvent, "type"))
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("data-viewer", { attrs: { data: _vm.currentlyViewingData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }