
<template>
  <fieldset class="p-2 pt-0 border border-grey">
    <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
    <div class="flex items-center p-2 flex-wrap gap-4">
      <select-suggestion
        class="flex-grow"
        column="name,email"
        model="User"
        color="dark"
        :label="$t('usuario')"
        v-model="values.user"
        :appendClearOption="true"
        ref="select-user"
        placeholderText="Digite o nome ou email do usuário"
        :itemTextFormat="
          (model) => `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`
        "
      />
      <div v-if="showButtons" class="flex gap-1 ml-auto">
        <vs-checkbox class="text-sm smaller-checkbox" v-model="values.autoFilter">{{ $t('automatico') }}</vs-checkbox>
        <vs-button :disabled="!values.user" class="py-auto px-4" @click="emitFetch">{{ $t('filtrar') }}</vs-button>
        <vs-button class="py-auto px-4" type="border" :disabled="!isFiltering" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
      </div>
    </div>
    
  </fieldset>
</template>

<script>
import SelectSuggestion from '@/components/SelectSuggestion.vue'

export default {
  components: { SelectSuggestion },

  props: {
    values: {
      type: Object,
      default: () => ({
        user: null,
        autoFilter: true,
      })
    },
    showButtons: {
      type: Boolean,
      default: false
    }
  },

  model: {
    event: 'input',
    prop: 'values'
  },

  data: () => ({
    questionnaireService: null,
    autoFilterBounceTimer: null,
  }),

  computed: {
    isFiltering() {
      return (
        this.values.user
      )
    },
    questionnaire_id() {
      return this._.get(this.values.questionnaire, 'id', null)
    }
  },

  watch: {
    'values.user'(val) {
      this.debouncedReload()
    },
    'values.autoFilter'(val) {
      if (val && this.isFiltering) this.emitFetch()
    },
  },

  methods: {
    emitFetch() {
      this.$emit('fetch', this.values)
    },
    updateFilters() {
      this.$emit('input', this.values)
    },
    debouncedReload() {
      if (this.values.autoFilter) {
        if (this.autoFilterBounceTimer) clearTimeout(this.autoFilterBounceTimer)
        this.autoFilterBounceTimer = setTimeout(this.emitFetch, 500)
      }
    },
    clearFilters() {
      this.values.user = null
      this.$refs['select-user'].reset()
      this.$emit('clearFilters')
    },
  },

  beforeMount() {
    //
  }
  
}
</script>

<style>

</style>