<template>
  <div class="grid grid-cols-12 gap-2">
    <!-- FILTROS -->
    <vx-card class="col-span-12">
      <user-client-exceptions-filters v-model="filters" @clearFilters="clear" @fetch="reloadData"/>
    </vx-card>

    <!-- Exceções do Usuário -->
    <user-client-exceptions-grid v-if="filters.user" class="col-span-12" ref="details-grid" :id="userId"/>
  </div>
</template>

<script>
import UserClientExceptionsFilters from './UserClientExceptionsFilters.vue'
import UserClientExceptionsGrid from './UserClientExceptionsGrid.vue'

export default {
  components: { UserClientExceptionsFilters, UserClientExceptionsGrid },
  data: () => ({
    filters: {
      user: null,
      autoFilter: true,
    },
    service: null,
    autoloadInterval: null
  }),

  created() {
    this.service = this.$exceptionService
  },

  beforeDestroy() {
    console.log('Shutting down autoload')
    this.shutdownAutoload()
  },

  computed: {
    userId() {
      return this._.get(this.filters, 'user.id', null);
    }
  },

  methods: {
    reloadData(filters) {
      
      if (this.filters.user !== null) {
        this.reloadGrid(filters)
        this.bootAutoload(filters, 10000)
      }
      
    },
    autoloadCycle(filters) {
      this.reloadGrid(filters)
    },
    bootAutoload(filters, msTimeout) {
      if (this.autoloadInterval === null) {
        this.autoloadInterval = setInterval(this.autoloadCycle, msTimeout, filters)
      } else {
        this.shutdownAutoload()
        this.bootAutoload(filters, msTimeout)
      }
    },
    shutdownAutoload() {
      clearInterval(this.autoloadInterval)
      this.autoloadInterval = null
    },
    extractFilters(filters) {
      const user = this._.get(filters, 'user', null)

      const extraction = {}

      if (user) extraction.user = user

      return extraction
    },
    reloadGrid(filters) {
      const filtersExtracted = this.extractFilters(filters)

      if (filtersExtracted.user) {
        const customFilters = []

        if ('user' in filtersExtracted) customFilters.push({
          key: 'user_id', value: filtersExtracted.user.id
        })

        this.grid().fillCustomFilters(
          customFilters
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['details-grid']
    },
  }
}
</script>